import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";
import base_URL from '../config';
import axios from 'axios';

function App(props) {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentFileStatus, setCurrentFileStatus] = useState('');
  const [footerHeight, setFooterHeight] = useState(0);

  const getUserById = async () => {
    try {
      const userId = localStorage.getItem('userId');
      if (userId === null) {
        return;
      }
      const res = await axios.get(base_URL+`/api/users?userId=${userId}`,{headers:{'Authorization':`Bearer ${localStorage.getItem('jwt')}`}});
      if (res?.data?.length > 0) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  useEffect(() => {
    onRouteChanged();
    getUserById();
    
    // Get the height of the footer
    const footer = document.querySelector('.footer');
    if (footer) {
      setFooterHeight(footer.offsetHeight);
    }
  }, [props.location]);

  const onRouteChanged = () => {
    console.log("ROUTE CHANGED");
    const { i18n } = props;
    const body = document.querySelector('body');
    if (props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    } else {
      body.classList.remove('rtl');
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/user-pages/login-1', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/reset-password','/user-pages/password', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    const isFullPage = fullPageLayoutRoutes.includes(props.location.pathname);
    setIsFullPageLayout(isFullPage);
  };

  const navbarComponent = !isFullPageLayout ? <Navbar setIsAuthenticated={setIsAuthenticated} /> : null;
  const sidebarComponent = !isFullPageLayout ? <Sidebar setCurrentFileStatus={setCurrentFileStatus} currentFileStatus={currentFileStatus} /> : null;
  const footerComponent = !isFullPageLayout ? <Footer /> : null;

  return (
    <>
      <div className="container-scroller">
        {sidebarComponent}
        <div className="container-fluid page-body-wrapper">
          {navbarComponent}
          <div className="main-panel">
            <div className="content-wrapper" style={{ paddingBottom: `${footerHeight}px` }}>
              <AppRoutes 
                setCurrentFileStatus={setCurrentFileStatus} 
                currentFileStatus={currentFileStatus} 
                setIsAuthenticated={setIsAuthenticated} 
                isAuthenticated={isAuthenticated} 
              />
            </div>
          </div>
        </div>
      </div>
      {footerComponent}
    </>
  );
}

export default withTranslation()(withRouter(App));