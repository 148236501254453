import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';

const Sidebar = ({ location, currentFileStatus, setCurrentFileStatus }) => {

  const [menuStates, setMenuStates] = useState({});


  const toggleMenuState = (menuState) => {
    setCurrentFileStatus(null);
    if (menuStates[menuState]) {
      setMenuStates({ ...menuStates, [menuState]: false });
    } else if (Object.keys(menuStates).length === 0) {
      setMenuStates({ [menuState]: true });
    } else {
      const newMenuStates = {};
      Object.keys(menuStates).forEach((i) => {
        newMenuStates[i] = false;
      });
      setMenuStates({ ...newMenuStates, [menuState]: true });
    }
  };

  // const onRouteChanged = () => {
    // const newMenuStates = {};
    // document.querySelector('#sidebar').classList.remove('active');
    // Object.keys(menuStates).forEach((i) => {
    //   newMenuStates[i] = false;
    // });

    // const dropdownPaths = [
    //   { path: '/apps', state: 'appsMenuOpen' },
    //   { path: '/pages', state: 'pagesMenuOpen' },
    //   { path: '/form-elements', state: 'formElementsMenuOpen' },
    //   { path: '/tables', state: 'tablesMenuOpen' },
    //   { path: '/processed', state: 'processedMenuOpen' },
    //   { path: '/approved', state: 'approvedMenuOpen' },
    //   { path: '/icons', state: 'iconsMenuOpen' },
    //   { path: '/charts', state: 'chartsMenuOpen' },
    //   { path: '/user-pages', state: 'userPagesMenuOpen' },
    //   { path: '/error-pages', state: 'errorPagesMenuOpen' },
    //   { path: '/admin', state: 'errorPagesMenuOpen' },
    // ];

    // dropdownPaths.forEach((obj) => {
    //   if (isPathActive(obj.path)) {
    //     newMenuStates[obj.state] = true;
    //   }
    // });

    // setMenuStates(newMenuStates);
  // };

  const isPathActive = (path, status) => {
    return location.pathname === path || currentFileStatus === status;
  };



  const isAdmin = localStorage.getItem('role') === 'Admin';
  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
        <a className="sidebar-brand brand-logo" href="/"><img src={require('../../assets/images/logo-new.png')} alt="logo" /></a>
        <a className="sidebar-brand brand-logo-mini" href="/"><img src={require('../../assets/images/favicon.png')} alt="logo" /></a>
      </div>
      <ul className="nav">
        <li className="nav-item profile">
          <div className="profile-desc">
            <div className="profile-pic">


            </div>
            <Dropdown alignRight>

              <Dropdown.Menu className="sidebar-dropdown preview-list">
                <a href="!#" className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-settings text-primary"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small"><Trans>Account settings</Trans></p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a href="!#" className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-onepassword  text-info"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small"><Trans>Change Password</Trans></p>
                  </div>
                </a>
                <div className="dropdown-divider"></div>
                <a href="!#" className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="mdi mdi-calendar-today text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small"><Trans>To-do list</Trans></p>
                  </div>
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </li>

        <li className={isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
        <div className={menuStates.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('MenuOpen')} data-toggle="collapse">

            <span className="menu-icon"><i className="mdi mdi-home"></i></span>
            <li className="nav-item"> <Link className={isPathActive('/dashboard/dashboard') ? 'nav-link active' : 'nav-link'} to="/dashboard/dashboard"><span className="menu-title"><Trans>Home</Trans></span></Link></li>
           <span className="menu-title"><Trans></Trans></span>
          </div>
        </li>
        {/* <li className={ isPathActive('/pages2') ? 'nav-item menu-items active' : 'nav-item menu-items' }>
            <div className={ menuStates.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => toggleMenuState('pagesMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-upload"></i>
              </span>
              <li className="nav-item"> <Link className={ isPathActive('/pages/uploaded') ? 'nav-link active' : 'nav-link' } to="/pages/uploaded"><Trans>Upload</Trans></Link></li>
              <span className="menu-title"><Trans></Trans></span>
              
              
            </div>
            
          </li> */}
        <li className={isPathActive('/tables/basic-table', 'New') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div className={menuStates.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('formElementsMenuOpen')} data-toggle="collapse">
            <span className="menu-icon">
              <i className="mdi mdi-file"></i>
            </span>
            <li className="nav-item"> <Link className={isPathActive('/tables/basic-table', 'New') ? 'nav-link active' : 'nav-link'} to="/tables/basic-table"><span className="menu-title"><Trans>Uploaded</Trans></span></Link></li>
            <Trans></Trans>


          </div>

        </li>
        {/* <li style={{ display: "none" }} className={isPathActive('/tables') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div className={menuStates.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('formElementsMenuOpen')} data-toggle="collapse">
            <span className="menu-icon">
              <i className="mdi mdi-file"></i>
            </span>
            <li className="nav-item" > <Link className={isPathActive('/tables/uploaddata') ? 'nav-link active' : 'nav-link'} to="/tables/uploaddata"><span className="menu-title"><Trans>Uploaded</Trans></span></Link></li>
            <Trans></Trans>


          </div>

        </li> */}
        <li className={isPathActive('/processed/processed', 'Processed') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div className={menuStates.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('processedMenuOpen')} data-toggle="collapse">
            <span className="menu-icon">
              <i className="mdi mdi-file"></i>
            </span>
            <li className="nav-item"> <Link className={isPathActive('/processed/processed', 'Processed') ? 'nav-link active' : 'nav-link'} to="/processed/processed"><span className="menu-title"><Trans>Processed</Trans></span></Link></li>
            <span className="menu-title"><Trans></Trans></span>

          </div>

        </li>
        <li className={isPathActive('/icons/approved', 'Approved') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div className={menuStates.chartsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('approvedMenuOpen')} data-toggle="collapse">
            <span className="menu-icon">
              <i className="mdi mdi-file"></i>
            </span>
            <li className="nav-item"> <Link className={isPathActive('/icons/approved', 'Approved') ? 'nav-link active' : 'nav-link'} to="/icons/approved"><span className="menu-title"><Trans>Approved</Trans></span></Link></li>
            <span className="menu-title"><Trans></Trans></span>

          </div>

        </li>
        <li className={isPathActive('/error-pages/errored', 'Error') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
          <div className={menuStates.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('MenuOpen')} data-toggle="collapse">
            <span className="menu-icon">
              <i className="mdi mdi-file"></i>
            </span>
            <li className="nav-item"> <Link className={isPathActive('/error-pages/errored', 'Error') ? 'nav-link active' : 'nav-link'} to="/error-pages/errored"><span className="menu-title"><Trans>Errored</Trans></span></Link></li>
            <span className="menu-title"><Trans></Trans></span>

          </div>

        </li>
        {isAdmin&&
         <li className={isPathActive('/admin/admin') ? 'nav-item menu-items active' : 'nav-item menu-items'}>
         <div className={menuStates.iconsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => toggleMenuState('MenuOpen')} data-toggle="collapse">
           <span className="menu-icon">
             <i className="mdi mdi-file"></i>
           </span>
           <li className="nav-item"> <Link className={isPathActive('/admin/admin') ? 'nav-link active' : 'nav-link'} to="/admin/admin"><span className="menu-title"><Trans>Admin Panel</Trans></span></Link></li>
           <span className="menu-title"><Trans></Trans></span>

         </div>

       </li>
        }
       

      </ul>
    </nav>
  );
};

export default withRouter(Sidebar);
