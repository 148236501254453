import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';

const Dashboard = React.lazy(() => import('./dashboard/Dashboard'));
const Uploaded = React.lazy(() => import('./pages/FileUpload'));
const Processed = React.lazy(() => import('./pages/Processed'));
const Search = React.lazy(() => import('./pages/Search'));
const Dropdowns = React.lazy(() => import('./pages/Dropdowns'));
const Typography = React.lazy(() => import('./pages/Typography'));
const BasicElements = React.lazy(() => import('./form-elements/BasicElements'));
const BasicTable = React.lazy(() => import('./pages/Uploaded'));
const Uploaddata = React.lazy(() => import('./pages/ViewFIle'));
const Approved = React.lazy(() => import('./pages/Approved'));
const ChartJs = React.lazy(() => import('./charts/ChartJs'));
const Errored = React.lazy(() => import('./pages/Errored'));
const admin = React.lazy(() => import('./admin/admin'));
const Login = React.lazy(() => import('./user-pages/Login'));
const ForgotPassword = React.lazy(() => import('./user-pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./user-pages/ResetPassword'));
const RegisterClient = React.lazy(() => import('./user-pages/RegisterClient'));

const AppRoutes = ({ isAuthenticated, setIsAuthenticated, setCurrentFileStatus }) => {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {isAuthenticated && <Route exact path="/dashboard" component={Dashboard} />}
        {isAuthenticated && <Route path="/pages/uploaded" component={Uploaded} />}
        {isAuthenticated && <Route path="/processed/processed" component={Processed} />}
        {isAuthenticated && <Route path="/processed/search" component={Search} />}
        {isAuthenticated && <Route path="/pages/dropdowns" component={Dropdowns} />}
        {isAuthenticated && <Route path="/pages/typography" component={Typography} />}
        {isAuthenticated && <Route path="/form-Elements/basic-elements" component={BasicElements} />}
        {isAuthenticated && <Route path="/tables/basic-table" component={BasicTable} />}
        {isAuthenticated && <Route path="/tables/Uploaddata" render={() => <Uploaddata setCurrentFileStatus={setCurrentFileStatus} />} />}
        {isAuthenticated && <Route path="/icons/approved" component={Approved} />}
        {isAuthenticated && <Route path="/charts/chart-js" component={ChartJs} />}
        {isAuthenticated && <Route path="/error-pages/errored" component={Errored} />}
        {isAuthenticated && <Route path="/admin/admin" component={admin} />}

        {isAuthenticated === false && <Route path="/user-pages/login-1" render={() => <Login setIsAuthenticated={setIsAuthenticated} />} />}
        {isAuthenticated === false && <Route path="/user-pages/password" component={ForgotPassword} />}
        {isAuthenticated === false && <Route path="/user-pages/reset-password" component={ResetPassword} />}
        {isAuthenticated === false && <Route path="/user-pages/register-2" component={RegisterClient} />}

        <Redirect to={isAuthenticated ? "/dashboard" : "/user-pages/register-2"} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
