import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ReactComponent as UserAvatar } from '../../assets/icons/user.svg';
const Navbar = ({ setIsAuthenticated }) => {
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const link = `/processed/search?search=${searchValue}`;
    history.push(link);
  }
  const handleLogout = () =>{
    setIsAuthenticated(false);
    localStorage.clear()
    history.push(`/user-pages/register-2`);
  }
 const user= localStorage.getItem("name");
 const role= localStorage.getItem("role");
  return (
    <nav className="navbar p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
      </div>
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <button className="navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="mdi mdi-menu"></span>
        </button>
        <ul className="navbar-nav w-100">
          <li className="nav-item w-100">
            <form onSubmit={handleSubmit} className="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
              <input onChange={(e) => setSearchValue(e.target.value)} value={searchValue} type="text" className="form-control" placeholder="Search " />
            </form>
          </li>
        </ul>
         <ul className="navbar-nav navbar-nav-right">
  {/* <Dropdown alignRight as="li" className="nav-item d-none d-lg-block">
  <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
  <i className="mdi mdi-view-module mdi-24px" style={{color:'#215CAC'}}></i>
     
    </Dropdown.Toggle>
    <Dropdown.Menu className="navbar-dropdown preview-list create-new-dropdown-menu"style={{boxShadow:' 4px 6px 35px -4px #215CAC',borderRadius:'10px'}}>
      <h6 className="p-3 mb-0 " style={{backgroundColor:'#215CAC', color:'white',textAlign:'center',borderRadius:'10px 10px 0 0'}}><Trans>Projects</Trans></h6>
    
      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
        <div className="preview-thumbnail">
          <div className="preview-icon  rounded-circle" style={{backgroundColor:'firebrick'}}>
            <i className="mdi mdi-file-outline " style={{color:'white'}}></i>
          </div>
        </div>
        <div className="preview-item-content">
          <p className="preview-subject ellipsis mb-1 " style={{color:'firebrick',fontSize:"18px"}}><Trans>Software Development</Trans></p>
        </div>
      </Dropdown.Item>
    
      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
        <div className="preview-thumbnail">
          <div className="preview-icon rounded-circle" style={{backgroundColor:'dodgerblue'}}>
            <i className="mdi mdi-web" style={{color:'white'}}></i>
          </div>
        </div>
        <div className="preview-item-content">
          <p className="preview-subject ellipsis mb-1 " style={{color:'dodgerblue',fontSize:'18px'}}><Trans>UI Development</Trans></p>
        </div>
      </Dropdown.Item>
    
      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
        <div className="preview-thumbnail">
          <div className="preview-icon rounded-circle" style={{backgroundColor:'blueviolet'}}>
            <i className="mdi mdi-layers" style={{color:'white'}}></i>
          </div>
        </div>
        <div className="preview-item-content">
          <p className="preview-subject ellipsis mb-1" style={{color:'blueviolet',fontSize:'18px'}}><Trans>Software Testing</Trans></p>
        </div>
      </Dropdown.Item>
     
      <p className="p-3 mb-0 text-center " style={{color:'#215CAC'}}><Trans>See all projects</Trans></p>
    </Dropdown.Menu>
  </Dropdown> */}
  {/* <li className="nav-item d-none d-lg-block">
    <a className="nav-link" href="!#" onClick={event => event.preventDefault()}>
      <i className="mdi mdi-view-grid "></i>
    </a>
  </li> */}
  {/* <Dropdown alignRight as="li" className="nav-item border-left">
    <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
      <i className="mdi mdi-message-text  mdi-24px" style={{color:'#215CAC'}}></i>
      <span className="count " style={{backgroundColor:'red'}}></span>
    </Dropdown.Toggle>
    <Dropdown.Menu className="navbar-dropdown preview-list" style={{boxShadow:' 4px 6px 35px -4px #215CAC',borderRadius:'10px'}}>
      <h6 className="p-3 mb-0" style={{backgroundColor:'#215CAC', color:'white',textAlign:'center',borderRadius:'10px 10px 0 0'}}><Trans>Messages</Trans></h6>
      
      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
        <div className="preview-thumbnail">
          <div className="preview-icon bg-dark rounded-circle">
            <img src={require('../../assets/images/faces/face4.jpg')} alt="profile" className="rounded-circle profile-pic" />
          </div>
        </div>
        <div className="preview-item-content">
          <p className="preview-subject ellipsis mb-1 text-dark"><Trans>Mark send you a message</Trans></p>
          <p className="text-muted mb-0"> 1 <Trans>Minutes ago</Trans> </p>
        </div>
      </Dropdown.Item>
     
      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
        <div className="preview-thumbnail">
          <div className="preview-icon bg-dark rounded-circle">
            <img src={require('../../assets/images/faces/face2.jpg')} alt="profile" className="rounded-circle profile-pic" />
          </div>
        </div>
        <div className="preview-item-content">
          <p className="preview-subject ellipsis mb-1 text-dark"><Trans>Cregh send you a message</Trans></p>
          <p className="text-muted mb-0"> 15 <Trans>Minutes ago</Trans> </p>
        </div>
      </Dropdown.Item>
     
      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
        <div className="preview-thumbnail">
          <div className="preview-icon bg-dark rounded-circle">
            <img src={require('../../assets/images/faces/face3.jpg')} alt="profile" className="rounded-circle profile-pic" />
          </div>
        </div>
        <div className="preview-item-content">
          <p className="preview-subject ellipsis mb-1 text-dark"><Trans>Profile picture updated</Trans></p>
          <p className="text-muted mb-0"> 18 <Trans>Minutes ago</Trans> </p>
        </div>
      </Dropdown.Item>
      
      <p className="p-3 mb-0 text-center "style={{color:'#215CAC'}}>4 <Trans>new messages</Trans></p>
    </Dropdown.Menu>
  </Dropdown> */}
  {/* <Dropdown alignRight as="li" className="nav-item border-left">
    <Dropdown.Toggle as="a" className="nav-link count-indicator cursor-pointer">
    <i className="mdi mdi-bell-outline mdi-24px" style={{color:'#215CAC'}}></i>
      <span className="count " style={{backgroundColor:'red'}}></span>
    </Dropdown.Toggle>
    <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list" style={{boxShadow:' 4px 6px 35px -4px #215CAC',borderRadius:'10px'}}>
      <h6 className="p-3 mb-0"style={{backgroundColor:'#215CAC', color:'white',textAlign:'center',borderRadius:'10px 10px 0 0'}}><Trans>Notifications</Trans></h6>
      
      <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
        <div className="preview-thumbnail">
          <div className="preview-icon rounded-circle" style={{backgroundColor:'darkgoldenrod'}}>
            <i className="mdi mdi-calendar" style={{color:'white'}}></i>
          </div>
        </div>
        <div className="preview-item-content">
          <p className="preview-subject mb-1 " style={{color:'royalblue'}}><Trans>Event today</Trans></p>
          <p className="text-muted ellipsis mb-0" style={{color:'darkgoldenrod'}}>
            <Trans>Just a reminder that you have an event today</Trans>
          </p>
        </div>
      </Dropdown.Item>
      
      <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
        <div className="preview-thumbnail">
          <div className="preview-icon  rounded-circle" style={{backgroundColor:'purple'}}>
            <i className="mdi mdi-settings " style={{color:'white'}}></i>
          </div>
        </div>
        <div className="preview-item-content">
          <h6 className="preview-subject mb-1 " style={{color:'mediumpurple'}}><Trans>Settings</Trans></h6>
          <p className="text-muted ellipsis mb-0" style={{color:'purple'}}>
            <Trans>Update dashboard</Trans>
          </p>
        </div>
      </Dropdown.Item>
     
      <Dropdown.Item className="dropdown-item preview-item" onClick={evt => evt.preventDefault()}>
        <div className="preview-thumbnail">
          <div className="preview-icon rounded-circle" style={{backgroundColor:'darkred'}}>
            <i className="mdi mdi-link-variant " style={{color:'white'}}></i>
          </div>
        </div>
        <div className="preview-item-content">
          <h6 className="preview-subject mb-1 " style={{color:'saddlebrown'}}><Trans>Launch Users</Trans></h6>
          <p className="text-muted ellipsis mb-0" style={{color:'darkred'}}>
            <Trans>New User wow</Trans>!
          </p>
        </div>
      </Dropdown.Item>
     
      <p className="p-3 mb-0 text-center " style={{color:'#215CAC'}}><Trans>See all notifications</Trans></p>
    </Dropdown.Menu>
  </Dropdown> */}
  <Dropdown alignRight as="li" className="nav-item">
    <Dropdown.Toggle as="a" className="nav-link cursor-pointer no-caret">
      <div className="navbar-profile">
        <UserAvatar className="img-xs rounded-circle" />
        {/* <img className="img-xs rounded-circle" src={require('../../assets/images/faces/face27.jpg')} alt="profile" /> */}
        <p className="mb-0 d-none d-sm-block navbar-profile-name " style={{color:'goldenrod',fontSize:'medium',fontWeight:'600'}}><Trans>{{user}} - {{role}}</Trans></p>
        <i className="mdi mdi-menu-down d-none d-sm-block text-dark"></i>
      </div>
    </Dropdown.Toggle>

    <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu" style={{boxShadow:' 4px 6px 35px -4px #215CAC',borderRadius:'10px'}}>
      <h6 className="p-3 mb-0 " style={{backgroundColor:'#215CAC', color:'white',textAlign:'center',borderRadius:'10px 10px 0 0'}}><Trans>Profile</Trans></h6>
     
      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
        <div className="preview-thumbnail">
          <div className="preview-icon  rounded-circle" style={{backgroundColor:'orangered'}}>
            <i className="mdi mdi-settings mdi-18px" style={{color:'white'}}></i>
          </div>
        </div>
        <div className="preview-item-content">
          <p className="preview-subject mb-1" style={{color:'orangered',fontSize:'18px',fontWeight:'500'}}><Trans>Settings</Trans></p>
        </div>
      </Dropdown.Item>
     
      <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()} className="preview-item">
        <div className="preview-thumbnail">
          <div className="preview-icon  rounded-circle" style={{backgroundColor:'darkorange'}}>
            <i className="mdi mdi-logout" style={{color:'white'}}></i>
          </div>
        </div>
        <div className="preview-item-content">
          <p className="preview-subject mb-1 text-dark">
            <Trans>
              <p onClick={handleLogout} style={{color:'darkorange',fontSize:'18px',fontWeight:'500'}}>Log Out</p>
            </Trans>
          </p>
        </div>
      </Dropdown.Item>
     
      <p className="p-3 mb-0 text-center " style={{color:'#215CAC'}}><Trans>Advanced settings</Trans></p>
    </Dropdown.Menu>
  </Dropdown>
</ul> 
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-format-line-spacing"></span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;