import React, { Component } from 'react';
import { FaYoutube } from "react-icons/fa";

class Footer extends Component {
  render() {
    return (
      <footer className="footer" style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        zIndex: 1000  // Ensure footer is above other content
      }}>
        <div className="px-3 py-2" style={{ 
          display: 'flex', 
          fontSize: '1rem',
          justifyContent: 'space-around',
          backgroundColor: '#215CAC',
          flexWrap: 'wrap'  // Allow wrapping on smaller screens
        }}>
          <span style={{display:'flex',alignItems:'center',color:'white', padding: '5px 0'}}>
            Copyright © 2022-23 AvenirDigital. All Rights Reserved.
          </span>
          <div className='py-2'>
            <ul style={{
              listStyleType:'none',
              textAlign:'left',
              paddingLeft:'0',
              display:'flex', 
              flexWrap: 'wrap',  // Allow wrapping on smaller screens
              gap:'30px',
              margin: 0
            }}>
              <li style={{fontSize:'1.1rem',color:'white'}}><a style={{color:'white'}} href="#PrivacyPolicy">Privacy Policy</a></li>
              <li style={{fontSize:'1.1rem',color:'white'}}><a style={{color:'white'}} href="#TermsandConditions">Terms and Conditions</a></li>
              <li style={{fontSize:'1.1rem',color:'white'}}><a style={{color:'white'}} href="#KnowledgeBase">Knowledge Base</a></li>
              <li style={{fontSize:'1.1rem',color:'white'}}><a style={{color:'white'}} href="#KnowledgeBase">Legal</a></li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;